import React, { useState} from 'react';
import useFetchData from '../hooks/fetchcategory.js';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

function Owldemo1({ vendorid, setLoading }) {

  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { data } = useFetchData({ vendorid, setLoading });

  const handleSearch = () => {
    navigate("/search", { state: { searchdata: search } });
  }


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('search-button').click();
    }
  }

    return (
      <div className='partners-bx mt-3'>
        <div className='container'>
          <div className='search'>
                <div className="input-group">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Search..." 
                    aria-label="Search" 
                    aria-describedby="search-button"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <button className="btn btn-primary" type="button" id="search-button" onClick={handleSearch} >
                <FaSearch/>
                  </button>
                </div>
              </div>
          <div className="row title" style={{ marginBottom: "20px" }}>
            <div className="bheads mhead" data-aos="zoom-in">
               Category List ({data.length})
            </div>
          </div>
        </div>
        <div className='container'>
        {data.length > 0 && (
    <div className='productlist'>
      {data.map(item => (
         

            <div key={item.catid} className="titem item">
              <div className="mag-img">
             

              
              <Link  to={`/${item.subcat > 0 ? item.url : item.url}`} className='icontitle'>    

              <img src={item.imgpath 
      ? `https://bulkify.in/photo/category/${item.imgpath}` 
      : `https://bulkify.in/photo/product/${item.imgpath1}`} className='img-fluid' alt="" title="" /> </Link>
               <Link to={`/${item.subcat > 0 ? item.url : item.url}`} className='title'>  <b> {item.category}</b></Link>
              
              </div>
            </div>

           

          ))}
    </div>
  )}
          
        </div>
      </div>
    );

}

export default Owldemo1;

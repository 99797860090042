import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../hooks/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons'; 
import Header from './header';
import Footer from './footer';
import UserLogin from './userlogin'; 
import useFetchData from '../hooks/fetchsubcategory.js';
import ProductList from './subcatproductlist.js';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa'; // Importing the search icon from Font Awesome

function Owldemo1({topmsg, catid, catname}) {
  const { setLoading, login, sessionId, sessionVid, cartItems } = useAuth();
  const { data } = useFetchData({ catid, sessionVid, setLoading });
const [catIds, setCatIds] = useState([]);
const [search, setSearch] = useState('');
const [searchdata, setSearchdata] = useState('');
  useEffect(() => {
    if (data.length > 0) {
      setCatIds(data.map(item => item.catid));
    }
  }, [data]);


  const [selectedCategory, setSelectedCategory] = useState("all");
  const categorySliderRef = useRef(null); 

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    scrollToCategory(category);
  };

  const scrollToCategory = (category) => {
    const slider = categorySliderRef.current;
    if (slider) {

      const categoryElement = slider.querySelector(`.cat-${category}`);
      if (categoryElement) {
        const categoryRect = categoryElement.getBoundingClientRect();
        const sliderRect = slider.getBoundingClientRect();

     
        const offset = categoryRect.left - sliderRect.left - (sliderRect.width / 2) + categoryElement.offsetWidth / 2;


        slider.scrollLeft += offset;
      }
    }
  };

  const scroll = (direction) => {
    if (categorySliderRef.current) {
      const slider = categorySliderRef.current;
      const scrollAmount = 200; 
      slider.scrollLeft += direction * scrollAmount;
    }
  };

 
  
  const handleSearch = () => {
    setSearchdata(search);
  }


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('search-button').click();
    }
  }


    return (
      <div className="App">
     
      {login && sessionId && sessionVid ? (
        
        <>
      
          <Header topmsg={topmsg} />
          <div className='midouter'>
      <div className='partners-bx mt-3'>
        <div className='container'>
          <div className="row title" style={{ marginBottom: "20px" }}>
            <div className="bheads mhead" data-aos="zoom-in">
               Subcategory List of {catname} ({data.length})
            </div>
          </div>
        </div>
        <div className='container'>
        {data.length > 0 && (
    <div className='productlist-item titem'>
      <div className="category-slider-container">
                  <button
                    className="arrow-btn left-arrow"
                    onClick={() => scroll(-1)}
                  >
                    &#8592; 
                  </button>

                  <div className="category-slider" ref={categorySliderRef}>
                  <a 
                      className={`cat-all d-flex align-items-center title ${selectedCategory === 'all' ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick("all")}
                    >
                      <b>All Categories</b> 
                    </a>
                  {data.map(item => (
                    <>
                   

<a 
                    key={item.catid} className={`cat-${item.catid} title ${selectedCategory === `${item.category}` ? 'active' : ''}`} 
                      onClick={() => handleCategoryClick(`${item.category}`)}
                    >
                         <img src={item.imgpath 
      ? `https://bulkify.in/photo/category/${item.imgpath}` 
      : `https://bulkify.in/photo/product/${item.imgpath1}`} className="img-fluid" alt="" title=""/>
                      <b>{item.category}</b> 
                    </a>
                    </>
                     ))}

                  </div>
                
                  <button
                    className="arrow-btn right-arrow"
                    onClick={() => scroll(1)}
                  >
                    &#8594; 
                  </button>
                </div>
                <div className="text-center alert">{selectedCategory === 'all' ? 'VIEW All PRODUCTS' : `PRODUCTS OF ${selectedCategory}`} </div>

                <div className='search'>
      <div className="input-group">
        <input 
          type="text" 
          className="form-control" 
          placeholder="Search..." 
          aria-label="Search" 
          aria-describedby="search-button"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button className="btn btn-primary" type="button" id="search-button" onClick={handleSearch} >
      <FaSearch/>
        </button>
      </div>
    </div>


                  <ProductList searchdata={searchdata} catid={catIds} selectedCategory={selectedCategory}/>
    </div>

  )}
          
        </div>
      </div>
          <Footer />
            <div className='bottomcart'>
            <Link
                to="/cart"
                className="border cartbox bottom_cartbox rounded py-1 px-3 d-flex align-items-center"
          
              >
                <FontAwesomeIcon icon={faShoppingCart} className="m-1 me-md-2" />
                <p className="d-none d-md-block mb-0">My cart</p> <span className='cartcount'> {cartItems.length}</span> 
              </Link>
              </div>

          </div> 
        </>
      ) : (
        <>
        <UserLogin />
        </>
      )}
    </div>
    );

}

export default Owldemo1;

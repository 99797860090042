import Header from './header';
import Footer from './footer';
import Login from './login';
import React, { useState } from 'react';
import useFetchData from '../hooks/vendor/fetchproduct.js';
import { Link, useNavigate  } from 'react-router-dom';
import { FaRupeeSign, FaSearch } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function App({ccatid}) {
  const navigate = useNavigate();
  const { vendorLogin, vendorSessionId, setLoading } = useAuth();
  const vid = vendorSessionId;
  const catid = ccatid || '';

  const [search, setSearch] = useState('');
const [searchdata, setSearchdata] = useState('');

  const { data } = useFetchData({searchdata, vid, catid, setLoading});
  
  const [quantity, setQuantity] = useState(1); 
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);



  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedDisc, setSelectedDisc] = useState(null);

  const handleVariantChange = (event) => {
    const variantValue = event.target.value;
    const variantDetail = selectedItem.price_details.find(priceDetail => priceDetail.variantValue1 === variantValue);

    if (variantDetail) {
      setSelectedVariant(variantValue);
      setSelectedPrice(variantDetail.price);
      setSelectedDisc(variantDetail.discount);
    }
  };


  const handleSearch = () => {
    setSearchdata(search);
  }


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('search-button').click();
    }
  }

  const handleEditButtonClick = (id) => {
    navigate(`/vendor/editproduct/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/delete-product.php?formtype=deleteProduct&id=${id}`);
      const data = response.data;
  
      if (data.Massage === "Successfully Delete") {
        window.location.reload();
      }

    } catch (error) {
      console.error('Error checking mobile number:', error);
    }
  };
    const options = {
      loop: true,
      margin: 10,
      nav: false,
      items: 1, 
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    };


    const togglePopup = (item = null) => {
      setSelectedItem(item);
      setSelectedVariant(null);
      setSelectedPrice(null);
      setSelectedDisc(null);
      setIsPopupVisible(!isPopupVisible);
    };
  return (
    <div className="App">
{vendorLogin && vendorSessionId ? (
        
     

<>
<Header />


<div className='midouter'>

<div className='partners-bx mt-3'>
        <div className='container'>
          <div className="row title align-items-center" style={{ marginBottom: "20px" }}>
            <div className="col-6 bheads mhead" data-aos="zoom-in">
            {data.length > 0 ? (
                  <h3>{catid ? data[0].category_name : 'Total Products'} ({data.length})</h3>
                ) : (
                  <h3>No product Found.</h3>
                )}
            </div>
            <div className="col-6 text-right bheads mhead" data-aos="zoom-in">
            <button className="btn btnn"> <Link to="/vendor/addproduct" className="btn btnn">Add New</Link> </button>
            </div>
            
          </div>
        </div>



        <div className='container'>
        <div className='row mb-3'>
<div className="col-md-12">
             <div className='search'>
      <div className="input-group">
      <input 
          type="text" 
          className="form-control" 
          placeholder="Search..." 
          aria-label="Search" 
          aria-describedby="search-button"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button className="btn btn-primary" type="button" id="search-button" onClick={handleSearch} >
      <FaSearch/>
        </button>
      </div>
    </div>

</div>
</div>

        {data.length > 0 && (
    <div className='productlist'   >
      {data.map(item => (
         


    <div className="titem item" key={item.id}>
              <div className="mag-img">
             
              <a  href='#' onClick={() => togglePopup(item)} className='icontitle'>        <img src={`https://bulkify.in/photo/product/${item.imgpath1}`} className='img-fluid' alt="" title="" /> </a>
              
              <a href='#' onClick={() => togglePopup(item)} className='title'>  <b> {item.title}</b></a>
             
             
        <div className='dflexbox md2'> <div className='price'>
  
  {item.price_details.length > 0 && item.price_details[0].discount > 0 && (
    <>
      
      <span>
      <FaRupeeSign />
        {(item.price_details[0].price - (item.price_details[0].price * item.price_details[0].discount) / 100).toFixed(2)}
        &nbsp; ({item.price_details[0].discount}% off) 
      </span>
    </>
  )}
  {item.price_details.length > 0 && (
  <span style={(item.price_details.length > 0 && item.price_details[0].discount > 0) ? { textDecoration: "line-through" } : {}}><FaRupeeSign /> {item.price_details[0].price } 
  </span>
   )}
</div>
               
                
        
         </div>

          


               <div className='dflexbox'> 
         
               <button   className='btn' onClick={() => handleEditButtonClick(item.id)}>
                            <b> Edit</b>
                          </button> 
         
      <a href="#" onClick={() => togglePopup(item)}  className='detailbtn' >
        <b>View </b>
      </a>
      <button   className='btn' onClick={() => handleDelete(item.id)}>
                            <b>  Delete</b>
                          </button> 
 

               
               
                 </div>
              </div>
            </div>

))}
</div>
)} 
    </div>
        </div>
      </div>



  <Footer/>



  {isPopupVisible && selectedItem && (
        <div className='popup' >
          <div className='popup-content'>
            <span className='close' onClick={togglePopup}>&times;</span>
         <div className='row'>

         <div className='col-12 col-md-6' >        <OwlCarousel className='owl-theme' {...options}>
         {[...Array(10).keys()].map(i => {
    const imagePath = selectedItem[`imgpath${i + 1}`]; // Dynamically access imgpath1 to imgpath10
    if (imagePath) { // Check if the image path is not empty
      return (
        <div className='item' key={i}>
          <a href={`https://bulkify.in/photo/product/${imagePath}`} data-fancybox="imggallery" className="limg" data-caption="">
            <img src={`https://bulkify.in/photo/product/${imagePath}`} alt="" title="" />
          </a>
        </div>
      );
    }
    return null; // Don't render anything if imgpath is empty
  })}
        


</OwlCarousel> </div>

<div className='col-12 col-md-6' >

<div className="mdright">
  <h4 className="modal-title">{selectedItem.title}</h4>



  <div className='row'>
  {selectedItem.pcode.length > 0 && (
  <div className="form-group col-6">
   
    <b>SKU: </b>
    {selectedItem.pcode}
  </div>
  )}
  {selectedItem.price_details.some(priceDetail => priceDetail.variantName1 !== "") && (
  <div className="form-group col-6">
    <b>{selectedItem.price_details[0].variantName1}: </b>
    <select className="form-select" aria-label="Default select example" onChange={handleVariantChange}
            value={selectedVariant || selectedItem.price_details[0].variantValue1}>
      {selectedItem.price_details.map((priceDetail, index) => (
        priceDetail.variantName1 !== "" && (
          <option key={index} value={priceDetail.variantValue1}>
            {priceDetail.variantValue1}
          </option>
        )
      ))}
    </select>
  </div>
)}
{selectedItem.price_details.length > 0 && (
      <div className="form-group col-6">
        <div className="quantity-controls mg100">
          <b>Qty: </b>
          <button className="sub" >-</button>
          <span>{quantity}</span>
          <button className="add">+</button>
        </div>
      </div>
  )}
      <div className="form-group col-12 pricemd">
      {selectedItem.price_details.length > 0 && (
      <div className='price'>
      <b>Price: </b>

  {(selectedDisc || (selectedItem.price_details.length > 0 && selectedItem.price_details[0].discount)) > 0 && (
    <>
      <span>
        <FaRupeeSign />
        {(
          (selectedPrice || selectedItem.price_details[0].price) - 
          ((selectedPrice || selectedItem.price_details[0].price) * (selectedDisc || selectedItem.price_details[0].discount) / 100)
        ).toFixed(2)}
        &nbsp; ({selectedDisc || selectedItem.price_details[0].discount}% off) 
        &nbsp;
      </span>
    </>
  )}
  {selectedItem.price_details.length > 0 && (
    <>
    <span style={(selectedDisc || selectedItem.price_details[0].discount) > 0 ? { textDecoration: "line-through" } : {}}>
    <FaRupeeSign /> {selectedPrice || selectedItem.price_details[0].price}
  </span>
  </>
   )}
</div>
)}
      </div>
    </div>



  <div className="form-group">
    <button className="btn btn-primary " > add to cart</button>
   
  </div>
</div>
  
   </div>
  
  
         </div>
          </div>
        </div>
      )}




</>



) : (
  <>
  <Login />
  </>
)}


    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
const LoginForm = ({setNewusr }) => {
  const { setLoading, login, handleLogout, sessionId, setLogin, saveSessionId, saveSessionVid } = useAuth();
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [gstno, setGstno] = useState('');
  const [othermob, setOthermob] = useState('');
  const [vcode, setVcode] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [newreg, setNewreg] = useState(false);
  const [newregdone, setNewregdone] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
      if (mobile) {
      fetch(`https://bulkify.in/api/checkuser.php?mobile=${mobile}`)
        .then(response => response.json())
        .then(data => {
          if (data.data === "New") {
            setNewregdone(true);
          }
        })
        .catch(error => console.error('Error fetching User Order Ids:', error));
      }
    }, [mobile,newregdone]);

  const handleMobileChange = (e) => {
    const value = e.target.value;
    const mobileRegex = /^\d{10}$/;
  
    if (!value || !mobileRegex.test(value)) {
        setNewreg(true);
        setNewregdone(false);
    } else {

      console.log("Valid mobile number");
    }
  
    setMobile(value);
  };

  const validateForm = () => {
    const newErrors = {};
    const mobileRegex = /^\d{10}$/; 

    if (!mobile || !mobileRegex.test(mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }

    if(newregdone && !name){
      newErrors.name = 'Name Required';
    }
    if(newregdone && !address){
      newErrors.address = 'Address Required';
    }

  if (!vcode) {
      newErrors.vcode = 'Ref. Code Required';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
    //formData.append('fname', fname);
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
                //setMessage(dataItem.Massage);
                 setLogin(true);
                 saveSessionId(dataItem.id); // Save session ID
                 saveSessionVid(dataItem.vid); // Save session ID 
                 localStorage.setItem('vmobile', '');
                 localStorage.setItem('vmobile', dataItem.vmobile);
                 setNewusr(true);
                navigate('/', { state: { data: dataItem } });
       
            }else if (dataItem.Massage === "Ref. Code Not Validate.</br>") {
                setVcode(dataItem.Massage);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
    


  };

  return (
    <> 
      <Header />
      <div id="login">
        <div className="login-container">
          <h1>Log in</h1>
          <form onSubmit={handleSubmit} action="https://bulkify.in/api/reg_user.php" method="post" encType="multipart/form-data" >
            <div className="input-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={handleMobileChange}
                
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

            {newregdone ? (
                <>
               <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                
              />
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="gstno">Gst</label>
              <input
                type="text"
                id="gstno"
                name="gstno"
                value={gstno}
                onChange={(e) => setGstno(e.target.value)}
                
              />
             
            </div>
            <div className="input-group">
              <label htmlFor="othermob">Alt. Mobile</label>
              <input
                type="text"
                id="othermob"
                name="othermob"
                value={othermob}
                onChange={(e) => setOthermob(e.target.value)}
                
              />
              
            </div>
                </>
              ) : (
                <>
                
                </>
              )}











            <div className="input-group">
              <label htmlFor="vcode">Ref. Code</label>
              <input
                type="text"
                id="vcode"
                name="vcode"
                value={vcode}
                onChange={(e) => setVcode(e.target.value)}
                
              />
              {errors.vcode && <p className="error">{errors.vcode}</p>}
            </div>

            

            <button type="submit" className="btn btn1">Sign in</button>
            {message && <p className="error text-center">{message}</p>}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginForm;

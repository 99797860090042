import React, { useState, useEffect } from "react";
import { Link  } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import imgg from '../assets/img/notfound.webp'; 

const NotFound = () => {
  return (
    <>
     <Header />
     <div className="notfound">
     <img src={imgg} />
      <p>The page you are looking for does not exist. <Link to='/'>Go back to Home.</Link></p>
    </div>
     <Footer />
    </>
    
  );
};

export default NotFound;
import React, { useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { useAuth } from '../hooks/AuthContext';
import { useLocation  } from 'react-router-dom';
function App() {
    const { login, sessionId, sessionVid, cartItems = [], updateCartItemQuantity, removeItemFromCart } = useAuth();
    const location = useLocation();
    const { message } = location.state || {};
    useEffect(() => {
       
        const removeAllItems = () => {
            cartItems.forEach(item => removeItemFromCart(item.id)); // Assuming each item has a unique id
        };

        removeAllItems();
    }, [cartItems, removeItemFromCart]); 

  return (
    <div className="App">
     
   
          <Header />
          <div className='content' id='thanks'>

    <div className="wrapper-2">
      <h1>Thank you !</h1>
      {message && <div dangerouslySetInnerHTML={{ __html: message }} />}

    </div>


</div>
          <Footer />
       
     
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function App() {
const [mobile, setMobile] = useState('');
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [remark, setRemark] = useState('');
 const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};
    const mobileRegex = /^\d{10}$/; 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!name){
        newErrors.name = 'Name Required';
      }

    if (!mobile || !mobileRegex.test(mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }

    if (!email || !emailRegex.test(email)) {
        newErrors.email = 'Please Type Valid Email';
      }

    if(!remark){
        newErrors.remark = 'Message Required';
      }
    


    return newErrors;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
      try {
          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          
          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item
            if (dataItem && dataItem.Massage === "Done") { // Ensure the message is "Done"
              navigate('/thanks', { 
                state: { 
                  message: '<p>We have Received Your Response. </p><p>One of Our Executive Will Contact You Soon.</p>' 
                } 
              });
            } 
           
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
    


  };






  return (
    <div className="App">


<>
<Header />

<div className='contactdetails-bx pt-3' >
     
   
      <div class="container " > 
      <div class="bg-light" > 

             <h3 class="bheads mhead" > Help & Support</h3>


             <p>Address - 150, Kamla Nehru School Ki Gali,  Haldion Ka Rasta, Johari Bazar, Jaipur.(302003) </p>
             <h3 class="bheads mhead" > Get Instant Answers to all your queries</h3>
<p>
Contact us on -
+919636511113
+919057777113
+919057177113
+919314069113</p>
<p>Mail us on - info@harshjewellersjaipur.com</p>

             <br></br>
             <h3 class="bheads mhead" >Get Answers Over Mail</h3>
             <div id="login" className="p-0">
                <div className="login-container">
                        
                <form onSubmit={handleSubmit} action="https://bulkify.in/api/support.php" method="post" encType="multipart/form-data" >
             <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="remark">Message</label>
              <textarea
                id="remark"
                name="remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
              {errors.remark && <p className="error">{errors.remark}</p>}
            </div>

            <button type="submit" className="btn btn1">Submit </button>
            {message && <p className="error text-center">{message}</p>}
          </form>
                </div>
             </div>
             
              </div>
              </div>


 

      </div>

  <Footer/>






</>






    </div>
  );
}

export default App;

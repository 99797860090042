import Header from './header';
import Footer from './footer';
import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/vendor/fetchcontactdetail.js';
import { Link } from 'react-router-dom';
import { FaRupeeSign } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
function App() {

  const { sessionVid, setLoading } = useAuth();
  const vid = sessionVid;
  const { data } = useFetchData({vid, setLoading});
  const [values, setValues] = useState({
    contactdetail:"",
    qrcode:"",
  });

  useEffect(() => {
    if (data.length > 0) {
      setValues({
        ...values,
        contactdetail:data[0]?.contact,
      });
       
      
    }
  }, [data]);
  


  return (
    <div className="App">


<>
<Header />

<div className='contactdetails-bx mt-3' >
     
   
      <div class="container " > 

             <div class="bheads mhead" > Contact details </div>


            <div className="bank-details-container mt-3 p-0" id="login">
            <div className="bank-details-card login-container " >
            <div className="bank-details-info input-group">
           
                  <div dangerouslySetInnerHTML={{ __html: values.contactdetail }} />
                </div>

                
            </div>
        </div>

             
              </div>


 

      </div>

  <Footer/>






</>






    </div>
  );
}

export default App;
